.scheduleNav {
	margin: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
}

.generateBtn {
	margin-left: 20px;
	padding: 10px;
	font-size: 16px;
	border: none;
	color: white;
	background-color: #673ab7;
	cursor: pointer;
}

.scheduleOptions {
	display: flex;
	align-items: center;
	gap: 20px;
}

.scheduleOption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.scheduleOption h3 {
	margin: 0;
}

.scheduleOption .scheduleOptionSelect {
	padding: 10px 5px;
	font-size: 16px;
}

.scheduleOption .scheduleOptionSelect:focus {
	outline: none;
}

.schedule {
	margin: 20px;
	display: flex;
	flex-direction: column;
	gap: 0;
	align-items: center;
	justify-content: flex-start;
}

.schedule .clock:first-child {
	border: 2px solid #555;
	font-weight: 600;
}

.schedule .clock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #555;
	padding: 5px;
	margin: 5px;
}

.schedule .clock p {
	width: 25%;
	text-align: center;
}
