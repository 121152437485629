.home {
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.home .clocker {
	display: flex;
	gap: 20px;
	align-items: center;
}

.home input {
	width: 300px;
	height: 26px;
}

.home button {
	width: 80px;
	height: 26px;
}

.home p {
	margin: 0;
}
